<template>
  <div>
    <app-page-detail page-title="View Tender" page-toolbar-title="View Tender">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @btn-save-action="updateData()"></action-button>
      </template>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" lg="9">

              <v-row>
                <v-col>
                  <card-expansion title="General" class="mb-3" :loading="loading">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field dense readonly v-model="tender.document_no_" placeholder="Document No." label="Document No." outlined hide-details class="mb-2"></v-text-field>
                        <v-text-field dense readonly v-model="tender.document_date" type="date" placeholder="Document Date" label="Document Date" outlined hide-details class="mb-2"></v-text-field>
                        <v-textarea dense readonly v-model="tender.description" placeholder="Description" label="Description" outlined hide-details class="mb-2"></v-textarea>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field dense readonly v-model="tender.document_type" placeholder="Document Type" label="Document Type" outlined hide-details class="mb-2"></v-text-field>
                        <v-text-field dense readonly v-model="tender.due_date" placeholder="Due Date" label="Due Date" type="Date" outlined hide-details class="mb-2"></v-text-field>
                        <!-- <v-select dense readonly v-model="selected_published" placeholder="Published" label="Published" hide-details outlined class="mb-2" :items="published_list" item-text="label" item-value="value" return-object></v-select> -->
                      </v-col>
                    </v-row>
                  </card-expansion>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <card-expansion title="Items" class="mb-3" :loading="card_item.loading" no-padding>
                    <template #action>
                      <v-btn icon text small @click="getDetails(true)">
                        <v-icon>mdi-sync</v-icon>
                      </v-btn>
                    </template>
                    <v-data-table :options.sync="card_item.datatable_options" :loading="card_item.datatable.loading" :loading-text="card_item.datatable.loadingText" :headers="card_item.datatable.columns" :items="card_item.datatable.data" :search="card_item.search" :server-items-length="card_item.datatable.totalRows" :items-per-page="card_item.datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="card_item.pageCount = $event">
                      <template v-slot:[`item.id`]="{ item }">
                        <v-btn icon text small :value="item.id" @click="viewDetail(item)">
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ card_item.search }}" found no results.</v-alert>
                    </v-data-table>
                    <div class="text-right pt-2 mx-auto" style="max-width: 600px">
                      <v-pagination v-model="card_item.datatable_options.page" :length="card_item.totalPage"></v-pagination>
                    </div>
                  </card-expansion>
                </v-col>
              </v-row>

            </v-col>

            <v-col cols="12" lg="3">
              <document-info :value="tender"></document-info>

              <app-comment :document_id="parseInt(tender.id)" :source_document="tender.source_document" :document_no_="tender.document_no_"></app-comment>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </app-page-detail>

    <app-dialog v-model="dialog_edit_detail.dialog" :title="dialog_edit_detail.title" max-width="500px">
      <v-row>
        <v-col>
          <card-expansion title="Edit">
            <v-text-field readonly v-model="dialog_edit_detail.data.item_no_" outlined hide-details dense class="mb-2" placeholder="Item No." label="Item No."></v-text-field>
            <v-textarea readonly v-model="dialog_edit_detail.data.description" outlined hide-details dense class="mb-2" placeholder="Description" label="Description"></v-textarea>
            <v-textarea readonly v-model="dialog_edit_detail.data.specification" outlined hide-details dense class="mb-2" placeholder="Specification" label="Specification"></v-textarea>
            <v-text-field readonly v-model="dialog_edit_detail.data.uom" outlined hide-details dense class="mb-2" placeholder="Unit" label="Unit"></v-text-field>
            <v-text-field readonly v-model="dialog_edit_detail.data.quantity" outlined hide-details dense class="mb-2" placeholder="Quantity" label="Quantity" type="number"></v-text-field>
          </card-expansion>
        </v-col>
      </v-row>
    </app-dialog>
  </div>
</template>

<script>
import ActionButton from './components/ActionButton.vue';
import DocumentInfo from './components/DocumentInfo.vue';
export default {
  components: {
    ActionButton,
    DocumentInfo,
  },
  data() {
    return {
      id: null,
      loading: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_approve: false,
      loading_cancel: false,
      dialog: false,
      modal1: false,
      tab: null,
      data_detail: {},
      tender: {},
      items: {},
      notFound: false,

      published_list: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      selected_published: {},

      card_item: {
        dialog: false,
        loading: null,
        search: "",
        datatable_options: {
          itemsPerPage: 20
        },
        itemsPerPageOption: [20, 50, 100, 500],
        totalPage: 0,
        datatable: {
          loading: null,
          loadingText: "Loading... Please wait...",
          totalRows: 0,
          columns: [
            { text: "#", value: "id", class: "text-no-wrap" },
            { text: "Item No.", value: "item_no_", class: "text-no-wrap" },
            { text: "Description", value: "description", class: "text-no-wrap" },
            // { text: "Specification", value: "document_type", class: "text-no-wrap" },
            { text: "UoM", value: "uom", class: "text-no-wrap" },
            { text: "Quantity", value: "quantity", class: "text-no-wrap", cellClass: "text-no-wrap py-0 text-end" },
          ],
          data: [],
        },
        filter: {}
      },

      dialog_pr: {
        title: "Add rrom PR NAV",
        loading: null,
        search: "",
        datatable_options: {
          itemsPerPage: 20
        },
        itemsPerPageOption: [20, 50, 100, 500],
        totalPage: 0,
        datatable: {
          loading: null,
          loadingText: "Loading... Please wait...",
          totalRows: 0,
          columns: [
            { text: "#", value: "checked", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "PR No.", value: "no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Line No.", value: "line_no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Item No.", value: "item_no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Description", value: "description", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Description 2", value: "description2", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Specification", value: "specification", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Due Date", value: "due_date", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "UoM", value: "uom", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Quantity", value: "quantity", class: "text-no-wrap text-end", cellClass: "text-no-wrap py-0 text-end" },
          ],
          data: [],
        },
        showFilter: false,
        filter: {
          requester_division: null,
        },
        requesterDivisionList: [],
        loading_apply: false,

      },
      loading_delete_detail: false,
      dialog_edit_detail: {
        dialog: false,
        data: {},
        title: "View Detail",
        loading: false
      }
    };
  },
  watch: {
    card_item: {
      handler() {
        if (this.card_item.dialog == true) {
          this.refreshDialogPr()
        }
      },
      deep: true,
    },
    selected_published(n){
      this.tender.published = n.value
    }
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "tender/detail");

      this.showLoadingOverlay(true);


      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("tender/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.tender = res.data.data.tender;
          this.selected_published = {
            label: (res.data.data.tender.published) ? 'Yes' : 'No',
            value: res.data.data.tender.published
          }
          this.items = res.data.data.items;
          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {

          if (error.response.status == 404) {
            this.redirect404()
          }

          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async getDetails(refresh) {
      const uri = "tender/data-detail";

      this.card_item.filter.tender_header_id = this.dtId;

      if (refresh) this.AxiosStorageRemove("POST", uri);
      this.card_item.datatable.loading = true;
      var formData = this.dataTableFormData(this.card_item.datatable_options, this.card_item.search, this.card_item.filter);

      await this.$axios
        .post(uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.card_item.datatable.data = resData.data.results;
            this.card_item.datatable.totalRows = resData.data.paging.total_rows;
            this.card_item.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.card_item.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.card_item.datatable.loading = false;
        });
    },

    

    async getRequesterDivision(refresh) {
      this.dialog_pr.requesterDivisionList = [];

      const uri = "tender/requester-division";
      if (refresh) this.AxiosStorageRemove("GET", uri);
      await this.$axios
        .get(uri)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            if (resData.data.length >= 1) {
              this.dialog_pr.requesterDivisionList.push("");
              resData.data.forEach((item) => {
                this.dialog_pr.requesterDivisionList.push(item);
              })
            }
          }
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
        });
    },

    refreshDialogPr() {
      this.getDataPr();
      this.getRequesterDivision();
    },

    viewDetail(item) {
      var newItem = item
      this.dialog_edit_detail.data = newItem;
      newItem = undefined
      this.dialog_edit_detail.dialog = true;
    },

  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("tender", "view", true);

    this.getDetail(true);
    this.getDetails(true);
  },
};
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  vertical-align: top;
  padding-top: 5px;
}
</style>
